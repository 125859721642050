<template>
  <nuxt-link :to="`/${href}`" class="article-thumb">
    <div class="article-thumb-image-wrap">
      <div
        class="article-thumb-image"
        :style="{ backgroundImage: `url(${src})` }"
        role="presentation"
      />
    </div>
    <div class="article-thumb-name">{{ name }}</div>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      href: {
        type: String,
        required: true,
        default: "",
      },
      src: {
        type: String,
        required: true,
      },
      id: {
        type: [String, Number],
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>

  .article-thumb {
    display: block;
    color: inherit;
    text-decoration: none;
  }
  .article-thumb-image-wrap {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
  }
  .article-thumb-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    border-radius: 4px;
    box-shadow: $shadow;

    margin-bottom: $base-spacing * 2;
    transition: transform 120ms ease-in-out, box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    &:hover {
      transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
      box-shadow: $hover-shadow;
    }
  }
  .article-thumb-name {
    max-width: 100%;
    white-space: pre-wrap;
    @include type('h6');
  }
</style>
