<template>
  <Container
    class="slider-container"
    :padding-top="margin"
    :padding-bottom="margin"
  >
    <div v-if="headline" class="slider-head">
      <div class="slider-head-content">
        <Headline
          :id="headlineAnchor"
          :level="headlineLevel"
          :size="3"
          class="slider-headline"
          no-margin
        >
          {{ headline }}
        </Headline>
        <InternalLink
          v-if="moreHref"
          :to="moreHref"
          size="mini"
          class="slider-internal-link"
        >
          {{ moreLabel }}
        </InternalLink>
      </div>
      <Paragraph v-if="subtext" class="slider-paragraph" size="small">
        {{ subtext }}
      </Paragraph>
    </div>

    <div
      v-if="items"
      ref="sliderwrap"
      class="slider-wrap"
      @touchstart="onTouchStart"
      @touchend="onTouchEnd"
    >
      <p class="at-text">
        Carousel with. Use the Previous and Next Button to Navigate.
      </p>
      <div class="scrollbutton-wrap scrollbutton-wrap-left">
        <button
          :aria-hidden="scrollPosition === 0"
          aria-label="Scroll left"
          :class="['scrollbutton', scrollPosition > 0 && 'visible']"
          @click="scrollLeft"
        >
          <SvgIcon icon="chevron-left" class="chevron-icon" />
        </button>
      </div>
      <Spinner :spin="loading" size="large" />
      <section
        class="slider"
        aria-label="Product Carousel"
        :style="[`--scroll:${scrollPosition}`]"
      >
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="slider-item"
          :style="`--index:${index + 1};`"
        >
          <ProductThumb
            v-if="sliderType === 'product'"
            :id="item.id"
            :src="item.imageUrl"
            :srcset="item.imageSrcset"
            :alt="item.imageAlt"
            :name="item.name"
            :ga-category="item.gaCategory"
            :rating="item.stars"
            :is-discontinued="item.discontinued"
            :is-new="item.newProduct"
            :price-range="item.priceRange"
            :href="item.urlPath"
            :layout="productLayout"
            :sizes="sizes"
            :taxons="item.taxons"
            :marketing-taxons="item.marketingTaxons"
          />
          <TaxonThumb
            v-if="sliderType === 'taxon'"
            :id="item.id"
            :src="item.iconUrl"
            :name="item.name"
            :alt-text="item.imageAlt"
            :href="item.urlPath"
          />
          <ArticleThumb
            v-if="sliderType === 'articles'"
            :id="item.id"
            :src="item.src"
            :name="item.name"
            :href="item.link"
          />
        </div>
      </section>

      <div class="scrollbutton-wrap scrollbutton-wrap-right">
        <button
          v-show="scrollPosition < items.length - columns"
          aria-label="Scroll right"
          :class="['scrollbutton', 'visible']"
          @click="scrollRight"
        >
          <SvgIcon icon="chevron-right" class="chevron-icon" />
        </button>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
  import ArticleThumb from "./ArticleThumb.vue"
  import createSizesString from "~/lib/createSizesString"

  export interface SliderItem {
    id: string
    imageUrl: string
    imageSrcset?: ImageSource[]
    imageAlt?: string
    name: string
    gaCategory?: string
    link?: string
    src?: string
    iconUrl?: string
    stars: number
    discontinued?: boolean
    newProduct?: boolean
    priceRange?: string[]
    urlPath: string
    taxons?: SpreeTaxon[]
    marketingTaxons?: string[]
  }

  interface Props {
    sliderType: "taxon" | "product" | "articles"
    productLayout?: "blank" | "default"
    items: SliderItem[]
    headline?: string
    headlineLevel?: string | number
    headlineAnchor?: string
    extraColumns?: number
    moreHref?: string
    moreLabel?: string
    subtext?: string
    margin?: boolean
  }

  const SWIPE_DURATION = 50 // amount of pixels moved to left or right considered a swipe

  const sliderwrap = ref(null)
  const scrollPosition = ref(0)

  const loading = ref(false)
  const touchstartX = ref(0)

  const props = withDefaults(defineProps<Props>(), {
    sliderType: "product",
    productLayout: "default",
    headline: "",
    extraColumns: 0,
    headlineLevel: 2,
    headlineAnchor: "",
    moreHref: "",
    moreLabel: "",
    subtext: "",
    margin: true,
  })

  const sizes = computed(() => {
    return createSizesString({
      mini: {
        spacing: 16 * columns.value,
      },
    })
  })

  const onTouchStart = (evt: TouchEvent) => {
    touchstartX.value = evt.changedTouches[0].screenX
  }

  const onTouchEnd = (evt: TouchEvent) => {
    const touchendX = evt.changedTouches[0].screenX
    const durationX = Math.abs(touchstartX.value - touchendX)

    if (durationX >= SWIPE_DURATION) {
      if (touchendX < touchstartX.value) {
        scrollRight()
      } else if (touchendX > touchstartX.value) {
        scrollLeft()
      }
    }
  }

  const scrollLeft = () => {
    if (scrollPosition.value > 0) {
      scrollPosition.value--
    }
  }

  const scrollRight = () => {
    scrollPosition.value++
  }

  const columns = computed(() => {
    if (!sliderwrap.value) {
      return 1
    }

    return parseInt(
      getComputedStyle(sliderwrap.value).getPropertyValue("--columns"),
    ) || 1
  })
</script>

<style lang="scss" scoped>
  $slider-button: $base-spacing * 12;

  .slider-container {
    --slider-gap: #{$base-spacing * 4};
    width: 100%;
    container-type: inline-size;
  }

  .slider-wrap {
    width: 100%;
    position: relative;
    max-width: 100%;
  }

  .slider-head {
    margin-bottom: $gutter;
  }

  .slider-head-content {
    display: flex;
    align-items: baseline;
    flex-flow: row-wrap;
    @include viewport("mini") {
      margin-bottom: $space-s;
    }
  }

  .slider-internal-link {
    align-self: flex-end;
    @include viewport("lg") {
      align-self: baseline;
    }
  }

  .slider-headline {
    margin-right: $base-spacing * 4;
  }

  .slider-paragraph {
    color: $gray-text;
    margin-bottom: $space-m;
    .cs-dark & {
      color: $gray-300;
    }
    @include viewport("mini") {
      margin-bottom: $space-s;
    }
  }

  .scrollbutton-wrap {
    top: 0;
    position: absolute;
    z-index: 50;

    transform: translateY(-50%);
    margin-top: calc((100% - var(--slider-gap)) / var(--columns) / 2);
    &-left {
      left: $slider-button * -0.5;
    }
    &-right {
      right: $slider-button * -0.5;
    }
  }

  .scrollbutton {
    cursor: pointer;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    border: none;
    width: $slider-button;
    border-radius: $slider-button;
    height: $slider-button;
    box-shadow: $shadow;

    transition:
      opacity 120ms ease-out,
      visibility 120ms ease-out,
      box-shadow 120ms ease-in-out,
      background-color 120ms ease-in-out,
      transform 120ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
    &:hover,
    &:focus {
      transform: scale(1.2);
    }
    &:focus {
      outline: none;
    }
  }
  .chevron-icon {
    transition: stroke 120ms ease-in-out;
    color: white;
    width: 24px;
    height: 24px;
  }

  .at-text {
    @extend %at-text;
  }

  .slider {
    --column-size: calc(
      (100% - var(--slider-gap) * (var(--columns) - 1)) / var(--columns)
    );
    display: grid;
    grid-gap: var(--slider-gap);
    grid-auto-flow: column;
    grid-auto-columns: var(--column-size);
    transition: transform 330ms ease-in-out;
    transform: translateX(
      calc(
        (100% / var(--columns) + var(--slider-gap) / var(--columns)) *
          var(--scroll) * -1
      )
    );
  }

  .slider-item {
    --show-item: calc(
      (var(--index) - var(--scroll)) *
        (var(--columns) - var(--index) + var(--scroll) + 1)
    );
    opacity: var(--show-item);
    transform: scale(clamp(0, var(--show-item), 1));
    transition:
      opacity 220ms ease-in-out,
      transform 500ms ease-in-out;
    transform-origin: 50% 50%;
  }

  @container (max-width: 479px) {
    .slider-wrap {
      --columns: v-bind("2 + props.extraColumns");
    }
  }
  @container (min-width: 480px) and (max-width: 799px) {
    .slider-wrap {
      --columns: v-bind("3 + props.extraColumns");
    }
  }

  @container (min-width: 800px) and (max-width: 956px) {
    .slider-wrap {
      --columns: v-bind("4 + props.extraColumns");
    }
  }

  @container (min-width: 960px) and (max-width: 1249px) {
    .slider-wrap {
      --columns: v-bind("5 + props.extraColumns");
    }
  }

  @container (min-width: 1250px) and (max-width: 3000px) {
    .slider-wrap {
      --columns: v-bind("6 + props.extraColumns");
    }
  }
</style>
